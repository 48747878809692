<template>
  <div class="dev-onboard_">
    <div
      class="modal"
      id="successAdd"
      tabindex="-1"
      aria-labelledby="successAddLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Added to List</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="text-align: center">
            <br />
            <img
              src="/img/marked.svg"
              style="margin-top: 30px; width: 150px"
            /><br /><br />
            <p style="margin-top: 30px">
              You have successfully added how you heard about us to list
            </p>
            <br /><br />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-light lightBorder"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      id="hearAbout"
      tabindex="-1"
      aria-labelledby="hearAboutLabel"
      aria-hidden="true"
      style=""
    >
      <div class="modal-dialog" style="width: 550px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">How did you hear about us</h5>
            <!--  
                data-bs-dismiss="modal"
              aria-label="Close"
             -->
            <button
              type="button"
              class="btn-close"
              @click="toggleFun()"
            ></button>
          </div>
          <form @submit="addNewHowYouHearAboutUs">
            <div class="modal-body" style="padding: 30px; padding-top: 20px">
              <div class="form-grouped">
                <br />
                <div class="alert alert-warning" v-if="isExist">
                  We already have
                  <b>{{ hear_about_us_value.toUpperCase() }}</b> on the list
                </div>

                <div class="alert alert-warning" v-if="isTooLong">
                  Word can not be greater than 15 characters
                </div>

                <div class="alert alert-danger" v-if="isError">
                  Error occurred while adding
                  <b>{{ hear_about_us_value }}</b> to list<br />
                  <code>{{ isErrorMsg }}</code>
                </div>

                <span class="modal_label"
                  >Tell us how you heard about Grupa</span
                >
                <br />

                <input
                  type="text"
                  name="hearAboutControl"
                  id="hearAboutControl"
                  :value="`${hear_about_us_value}`"
                  v-on:keyup="updateData"
                  @keydown.space.prevent
                  @paste="removeEventSpaces"
                  @drop="removeEventSpaces"
                  placeholder="e.g LinkedIn, Twitter e.t.c"
                  required
                  class="form-control"
                />
              </div>
              <br />
            </div>

            <div class="modal-footer">
              <div
                v-if="isLoading"
                style="right: 0%; right: 0; margin-top: 0%; text-align: center"
                id="loaderCal"
              >
                <v-progress-circular
                  :size="30"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                {{ "adding to list..." }}
              </div>
              <button
                v-if="!isLoading"
                type="submit"
                class="btn btn-primary p-3"
                :disabled="
                  isExist ||
                  isTooLong ||
                  hear_about_us_value == '' ||
                  hear_about_us_value.length <= 1
                "
              >
                Add to List
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { addHearAboutUs } from "@/api/client";
export default {
  data: () => ({
    spinner: false,
    hear_about_us_value: "",
    isExist: false,
    isTooLong: false,
    isLoading: false,
    isError: false,
    isErrorMsg: "",
  }),
  props: {
    getHearAboutUsArray: {
      type: Array,
      required: true,
    },
    toggleModal: {
      type: Boolean,
      require: true,
    },
    toggleFun: {
      type: Function,
      require: true,
    },

    finishSaveToList: {
      type: Function,
      require: true,
    },
  },

  methods: {
    updateData(evt, index) {
      this.isError = false;

      let { name, value } = evt.target;

      this.hear_about_us_value = value.replace(/ /g, "");

      if (this.hear_about_us_value.length > 15) {
        this.isTooLong = true;
      } else {
        this.isTooLong = false;
      }

      if (!value == "") {
        this.isExist = this.getHearAboutUsArray.some(
          (data) => data.name.toLowerCase() === value.toLowerCase()
        );
      } else {
        this.isExist = false;
      }
    },
    removeEventSpaces(e) {
      e.preventDefault();
      const left = e.target.value.substring(0, e.target.selectionStart);
      const right = e.target.value.substring(
        e.target.selectionEnd,
        e.target.value.length
      );
      const pasted = (e.dataTransfer || e.clipboardData)
        .getData("text")
        .replace(/ /g, "");
      e.target.value = left + pasted + right;
    },
    async addNewHowYouHearAboutUs(event) {
      event.preventDefault();
      this.isLoading = true;
      this.isError = false;

      const payload = {
        name: this.hear_about_us_value,
      };
      await addHearAboutUs(payload)
        .then((response) => {
          // console.log(response.data.data);
          this.isLoading = false;
          this.finishSaveToList(response.data.data);
          $("#hearAbout").modal("hide");
          $("#successAdd").modal("show");
        })
        .catch((error) => {
          this.isError = true;
          this.isErrorMsg = error;
          this.isLoading = false;
        });
    },
  },
  watch: {
    toggleModal: function (newItem, oldItem) {
      if (newItem === true) {
        $("#hearAbout").modal("show");
      } else {
        $("#hearAbout").modal("hide");
      }
    },
  },
};
</script>

<style scoped>
.lightBorder {
  border: 1px solid #eee;
}
.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* primary/700 (text) */

  color: #84878b;
  padding-bottom: 10px;
}

.profile-card-2 {
  text-align: left;
  background: #ffffff;

  border-bottom: 1px solid #eee;
  padding: 20px;
}
.start_up_line {
  border: 0.5px solid #e4e5e7;
}

.start_up_data {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding-top: 30px;
  padding-bottom: 20px;

  color: #1b1e22;
}
.start_up_edit {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;

  text-align: right;
  cursor: pointer;
  color: #0781f2;
}
.start_up_label {
  font-family: "Moderat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  text-transform: uppercase;

  color: #979da5;
}

.investor_data {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: auto;

  height: 31px;

  /* primary/0 */

  background: #ffffff;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.investor_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 20px;
  padding-top: 20px;
}

div.title {
  text-align: left;
  padding-top: 56px;
  padding-left: 5px;
  font-size: 23px;
  font-weight: bold;
}
.form-container {
  width: 588px;
  padding-top: 20px;
}
.div-label {
  text-align: left;
  font-size: 14px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 145%;
}
.form-control {
  border: 1px solid #e4e5e7;
}
.form-control {
  margin-top: 4px;
  margin-bottom: 0px;
}
.form-grouped {
  margin-top: 0px;
}

.form_errors {
  color: red;
}
.check-product-name {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 8px;
}
.light-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
  text-align: left;
}
.chip-btn-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 8px;
}
.button-container {
  /*display: flex;*/
  justify-content: flex-end;
  margin: 48px 0px;
  float: left;
}
.text-box-hint {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 8px;
}
.medium-combo-box .v-text-field {
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 130%;
}

.textbox-wrapper {
  display: flex;
}
.textbox-wrapper div {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  /* background-color: #fff; */
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0;
  width: 42px;
  background: black;
  color: white;
  height: 51px;
}
.light-text-label {
  color: #979da5;
}
.display-inline {
  display: inline-block;
  /* padding-top: 50px; */
}
.list-wrapper {
  display: flex;
  margin-top: 5px;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;
}
.label-description {
  color: #979da5;
}
.brief_counter {
  margin-bottom: 28px;
}
@media (max-width: 768px) {
  .form-container {
    width: 100%;
  }
  .button-container {
    float: none;
  }
  .grupa-blue-btn {
    width: 100%;
  }
  .list-wrapper {
    width: 100%;
  }
  div.title {
    padding-top: 32px;
  }
  .chip-btn-container {
    width: 100%;
  }
  .form-grouped {
    margin-top: 32px;
  }
}
</style>
