var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dev-onboard_" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "hearAbout",
          tabindex: "-1",
          "aria-labelledby": "hearAboutLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "550px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("How did you hear about us"),
                ]),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleFun()
                    },
                  },
                }),
              ]),
              _c("form", { on: { submit: _vm.addNewHowYouHearAboutUs } }, [
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { padding: "30px", "padding-top": "20px" },
                  },
                  [
                    _c("div", { staticClass: "form-grouped" }, [
                      _c("br"),
                      _vm.isExist
                        ? _c("div", { staticClass: "alert alert-warning" }, [
                            _vm._v(
                              "\n                We already have\n                "
                            ),
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.hear_about_us_value.toUpperCase())
                              ),
                            ]),
                            _vm._v(" on the list\n              "),
                          ])
                        : _vm._e(),
                      _vm.isTooLong
                        ? _c("div", { staticClass: "alert alert-warning" }, [
                            _vm._v(
                              "\n                Word can not be greater than 15 characters\n              "
                            ),
                          ])
                        : _vm._e(),
                      _vm.isError
                        ? _c("div", { staticClass: "alert alert-danger" }, [
                            _vm._v(
                              "\n                Error occurred while adding\n                "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.hear_about_us_value))]),
                            _vm._v(" to list"),
                            _c("br"),
                            _c("code", [_vm._v(_vm._s(_vm.isErrorMsg))]),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "modal_label" }, [
                        _vm._v("Tell us how you heard about Grupa"),
                      ]),
                      _c("br"),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "hearAboutControl",
                          id: "hearAboutControl",
                          placeholder: "e.g LinkedIn, Twitter e.t.c",
                          required: "",
                        },
                        domProps: { value: "" + _vm.hear_about_us_value },
                        on: {
                          keyup: _vm.updateData,
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                          paste: _vm.removeEventSpaces,
                          drop: _vm.removeEventSpaces,
                        },
                      }),
                    ]),
                    _c("br"),
                  ]
                ),
                _c("div", { staticClass: "modal-footer" }, [
                  _vm.isLoading
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            right: "0",
                            "margin-top": "0%",
                            "text-align": "center",
                          },
                          attrs: { id: "loaderCal" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              size: 30,
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                          _vm._v(
                            "\n              " +
                              _vm._s("adding to list...") +
                              "\n            "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isLoading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary p-3",
                          attrs: {
                            type: "submit",
                            disabled:
                              _vm.isExist ||
                              _vm.isTooLong ||
                              _vm.hear_about_us_value == "" ||
                              _vm.hear_about_us_value.length <= 1,
                          },
                        },
                        [_vm._v("\n              Add to List\n            ")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "successAdd",
          tabindex: "-1",
          "aria-labelledby": "successAddLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", staticStyle: { width: "450px" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Added to List"),
                ]),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: {
                    type: "button",
                    "data-bs-dismiss": "modal",
                    "aria-label": "Close",
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("br"),
                  _c("img", {
                    staticStyle: { "margin-top": "30px", width: "150px" },
                    attrs: { src: "/img/marked.svg" },
                  }),
                  _c("br"),
                  _c("br"),
                  _c("p", { staticStyle: { "margin-top": "30px" } }, [
                    _vm._v(
                      "\n            You have successfully added how you heard about us to list\n          "
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                ]
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light lightBorder",
                    attrs: { type: "button", "data-bs-dismiss": "modal" },
                  },
                  [_vm._v("\n            Close\n          ")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }